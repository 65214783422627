import React, { createContext, useContext, useEffect, useState } from 'react';

interface TicketProviderType {
    ticket: any,
    getTicket: () => any,
    setTicket: (ticket: any) => void,
    clearTicket:  () => any,
}

const TicketContext = createContext<TicketProviderType>({
    ticket: {},
    getTicket: () => null,
    setTicket: () => {},
    clearTicket: () => null,
});

export const TicketProvider: React.FC = ({ children }: any) => {
  const [ticket, setTicket] = useState<string | null>(() => {
    return localStorage.getItem('setTicket');
  });

  useEffect(() => {
    if (ticket) {
      localStorage.setItem('setTicket', ticket);
    } else {
      localStorage.removeItem('setTicket');
    }
  }, [ticket]);

  const getTicket = () => {
    return ticket;
  };

  const clearTicket = () => {
    setTicket(null);
    localStorage.removeItem('setTicket');
  }

  return (
    <TicketContext.Provider value={{ ticket, getTicket, setTicket, clearTicket }}>
      {children}
    </TicketContext.Provider>
  );
};

export const useTicket = (): TicketProviderType => useContext(TicketContext);