import {
  Button,
  MenuItem,
  SelectDialog,
  StandardListItem,
} from "@ui5/webcomponents-react";
import React, { useState } from "react";

export default function SinglePropertyViewComponent(props: any) {
  const { property, defaultImage, handleRedirection } = props;
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  return (
    <div className="flex">
      <div className="w-1/2">
        <img
          src={property.model.image || defaultImage}
          className="h-full w-full object-cover"
          alt="Imagen de la Card"
        />
      </div>
      <div className="w-full h-1/2 p-4 flex flex-col justify-between">
        <div>
          <p className="font-bold">{property.project.name}</p>
          <p>Modelo {property.model.name}</p>
        </div>
        <div className="flex justify-end">
          {property?.model?.documents?.length > 1 && (
            <>
              <Button
                onClick={() => {
                  setDialogIsOpen(true);
                }}
                className="bg-gray-500 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded"
              >
                Descargar archivos
              </Button>
              <SelectDialog
                open={dialogIsOpen}
                headerText="Seleccione archivo"
                onAfterClose={() => setDialogIsOpen(false)}
              >
                {property.model.documents.map((document: any, key: number) => (
                  <a
                    href={document.file}
                    target="_blank"
                    className="mr-2"
                    key={`${key} - ${document.description}`}
                  >
                    <MenuItem icon="add-document" text={document.description} />
                    <StandardListItem icon="add-document" description="PDF">
                      {document.description}
                    </StandardListItem>
                  </a>
                ))}
              </SelectDialog>
            </>
          )}
          <a
            href={property.model.documents[0]?.file}
            target="_blank"
            className="mr-2"
          >
            {property?.model?.documents?.length === 1 && (
              <Button className="bg-gray-500 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded">
                Descargar archivo
              </Button>
            )}
            {property?.model?.documents?.length === 0 && (
              <Button className="bg-gray-500 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded" disabled>
                Descargar archivo
              </Button>
            )}
          </a>
          <Button
            className="affirmative-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => handleRedirection("/send-ticket", property)}
          >
            Ingresar solicitud
          </Button>
        </div>
      </div>
    </div>
  );
}
