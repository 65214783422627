import {
  Card,
  Panel,
  Title,
  Button,
  InputDomRef,
  Ui5CustomEvent,
  Input,
  Table,
  ComboBox,
  ComboBoxItem,
  ComboBoxDomRef,
  Label,
  TableCell,
  TableColumn,
  TableRow,
} from "@ui5/webcomponents-react";
import React, { useEffect, useState } from "react";
import { getUserData } from "../../../api/user";
import { useAuth } from "../../../providers/AuthProvider";
import { getProperties, getTicketsFromProperty } from "../../../api/tickets";
import { ComboBoxSelectionChangeEventDetail } from "@ui5/webcomponents/dist/ComboBox";
import { useNavigate } from "react-router-dom";
import { useProperty } from "../../../providers/PropertyProvider";
import { useTicket } from "../../../providers/TicketProvider";
import RemarksListModal from "./RemarksListModal";
import HeaderComponent from "../../components/Header/HeaderComponent";
import SinglePropertyViewComponent from "./SinglePropertyViewComponent";
import PersonalDataEditComponent from "./PersonalDataEditComponent";
import BusyIndicatorComponent from "../../components/BusyIndicator/BusyIndicatorComponent";

export default function PersonalDataComponent() {
  const { getOwnerToken } = useAuth();
  const { property, setProperty } = useProperty();
  const { ticket, setTicket } = useTicket();
  const navigate = useNavigate();
  const [selectedRemarks, setSelectedRemarks] = useState<{}>({});
  const [isPickedTicket, setPickedTicket] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [personalData, setPersonalData] = useState({
    name: "",
    dni: "",
    phone: "",
    mail: "",
  });
  const [pickedProperty, setPickedProperty] = useState({
    id: 0,
    address: "",
    project: {
      name: "",
      phase: "",
    },
    model: {
      name: "",
      image: null,
      documents: [
        {
          description: "",
          file: "",
        },
      ],
      sites: [
        {
          id: 0,
          name: "",
        },
      ],
    },
  });
  const [properties, setProperties] = useState([
    {
      id: 0,
      address: "",
      project: {
        name: "",
        phase: "",
      },
      model: {
        name: "",
        image: null,
        documents: [
          {
            description: "",
            file: "",
          },
        ],
        sites: [
          {
            id: 0,
            name: "",
          },
        ],
      },
    },
  ]);
  const [ticketsList, setTicketsList] = useState([
    {
      code: 0,
      status: "",
      title: "",
      phase: "",
      project: "",
      location: {
        address: "",
        model: "",
      },
      manager: {
        name: "",
        mail: "",
      },
      remarks: [
        {
          item: 0,
          remark: "",
          images: [],
          status: "",
          site: "",
        },
      ],
      visits: [
        {
          status: "",
          date: "",
        },
      ],
    },
  ]);
  const defaultImage = "defaultImage.jpg";

  useEffect(() => {
    const getDataFromUser = async () => {
      const response: any = await getUserData(getOwnerToken());
      if (
        response.status === 'failed'
      ) {
        return navigate('/error/500');
      }
      if ( response.status === 'unauthorized' ) {
        return navigate('/error/403');
      }
      setPersonalData(response);
    };

    const getPropertiesFromUser = async () => {
      const response = await getProperties(getOwnerToken() || "");
      setLoadingComponent(false);
      if (
        response.status === 'failed'
      ) {
        return navigate('/error/500');
      }
      if ( response.status === 'unauthorized' || response.status === 'unavailable' ) {
        return navigate('/error/403');
      }
      setProperties(response);
    };
    if (!getOwnerToken()) {
        return navigate('/error/403');
    }
    setLoadingComponent(true);

    getDataFromUser();
    getPropertiesFromUser();
  }, []);

  useEffect(() => {
    const getAllTickets = async () => {
      const response = await getTicketsFromProperty(
        getOwnerToken(),
        pickedProperty.id
      );
      if (
        response.status === 'failed'
      ) {
        return navigate('/error/500');
      }
      if ( response.status === 'unauthorized' ) {
        return navigate('/error/403');
      }
      setTicketsList(response);
      setLoadingComponent(false);
    };
    setLoadingComponent(true);
    getAllTickets();
  }, [pickedProperty]);

  const handleRedirection = (link: string, object?: any) => {
    setProperty(object);
    navigate(link);
  };

  const handleScheduleVisitRedirection = (object: any) => {
    setTicket({...object, location: pickedProperty.id});
    setPickedTicket(true);
  };

  useEffect(() => {
    if (isPickedTicket) navigate("/schedule-visit/reschedule");
  }, [isPickedTicket]);

  const handleComboBoxSelection = async (
    e: Ui5CustomEvent<ComboBoxDomRef, ComboBoxSelectionChangeEventDetail>
  ) => {
    e.preventDefault();
    const foundProperty = properties.find(
      (property: any) => property.address === e.target.value
    );
    if (foundProperty) {
      setPickedProperty(foundProperty);
    } else {
      setPickedProperty({
        id: 0,
        address: "",
        project: {
          name: "",
          phase: "",
        },
        model: {
          name: "",
          image: null,
          documents: [
            {
              description: "",
              file: "",
            },
          ],
          sites: [
            {
              id: 0,
              name: "",
            },
          ],
        },
      });
    }
  };

  return (
    <>
      <div className="m-20">
        <Card className="cards-background" 
          style={{ opacity: loadingComponent ? 0.5 : 1 }}>
          <HeaderComponent></HeaderComponent>
          <div className="draaft-front__landing-card__header m-3">
            <Title level="H2">MI HOGAR GPR</Title>
          </div>
          <BusyIndicatorComponent
            loading={loadingComponent}
          ></BusyIndicatorComponent>
          <div className="m-5 cards-background">
            <Panel
              accessibleRole="Form"
              headerLevel="H2"
              headerText="Mis PROPIEDADES"
              onToggle={function _a() {}}
              collapsed={false}
              className="panel-headers"
            >
              {properties && properties.length === 1 && (
                <SinglePropertyViewComponent
                  property={properties[0]}
                  defaultImage={defaultImage}
                  handleRedirection={handleRedirection}
                />
              )}
              {properties &&
                properties.map((property: any, key: number) => {
                  return (
                    <Panel
                      accessibleRole="Form"
                      headerLevel="H2"
                      headerText={property.address}
                      onToggle={function _a() {}}
                      collapsed={key === 0}
                    >
                      <SinglePropertyViewComponent
                        property={property}
                        defaultImage={defaultImage}
                        handleRedirection={handleRedirection}
                      />
                    </Panel>
                  );
                })}
            </Panel>
            <Panel
              accessibleRole="Form"
              headerLevel="H2"
              headerText="MIS SOLICITUDES"
              onToggle={function _a() {}}
              collapsed={true}
              className="panel-headers mt-5 mb-5"
            >
              <div className="mt-2 ml-2 mr-3">
                <ComboBox
                  onChange={(e) => handleComboBoxSelection(e)}
                  valueState="None"
                  placeholder="Elija propiedad"
                  className="m-1 w-full"
                  required
                >
                  {properties.map((property: any) => (
                    <ComboBoxItem
                      key={`${property.id} - ${property.address}`}
                      text={property.address}
                    />
                  ))}
                </ComboBox>
                {ticketsList.length > 0 && (
                  <Table
                    className="mt-5"
                    columns={
                      <>
                        <TableColumn minWidth={800} popinText="Supplier">
                          <Label>Nº Ticket</Label>
                        </TableColumn>
                        <TableColumn style={{ width: "12rem" }}>
                          <Label>Status del ticket</Label>
                        </TableColumn>
                        <TableColumn
                          demandPopin
                          minWidth={600}
                          popinText="Weight"
                        >
                          <Label>Título</Label>
                        </TableColumn>
                        <TableColumn>
                          <Label>Estado de visita</Label>
                        </TableColumn>
                        <TableColumn>
                          <Label>Fecha de visita</Label>
                        </TableColumn>
                        <TableColumn>Acciones</TableColumn>
                      </>
                    }
                  >
                    {ticketsList.map((ticket: any) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell>
                              <Label>{ticket.code}</Label>
                            </TableCell>
                            <TableCell>
                              <Label>{ticket.status}</Label>
                            </TableCell>
                            <TableCell>
                              <Label>{ticket.title}</Label>
                            </TableCell>
                            <TableCell>
                              <Label>
                                {ticket.visits.length > 0
                                  ? ticket.visits[0].status
                                  : "-"}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <Label>
                                {ticket.visits.length > 0
                                  ? `${ticket.visits[0].date} / ${ticket.visits[0].module}`
                                  : "-"}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <>
                                <RemarksListModal
                                  remarksList={ticket.remarks}
                                  selectedRemarks={selectedRemarks}
                                  setSelectedRemarks={setSelectedRemarks}
                                />
                              </>
                              <Button
                                className="affirmative-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() =>
                                  handleScheduleVisitRedirection(
                                    ticket
                                  )
                                }
                                disabled={ticket.visits.length > 0 ? ticket.visits[0].status === 'Ejecutada' : false}
                              >
                                Reagendar visita
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </Table>
                )}
              </div>
            </Panel>
            <PersonalDataEditComponent
              personalData={personalData}
              userToken={getOwnerToken()}
            />
          </div>
        </Card>
      </div>
    </>
  );
}
