"use client";
import { getAvailableHours, scheduleHour } from "../../../api/visits";
import {
  Card,
  Title,
  DatePicker,
  List,
  StandardListItem,
  Dialog,
  Input,
  Label,
  Button,
  Bar,
  MessageBox,
  MessageBoxActions,
  DatePickerDomRef,
  Ui5CustomEvent,
  MessageStrip,
} from "@ui5/webcomponents-react";
import { useEffect, useState } from "react";
import React from "react";
import { DatePickerChangeEventDetail } from "@ui5/webcomponents/dist/DatePicker";
import formatDate, { formatDateToISO } from "../../../utils/date";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";
import { useTicket } from "../../../providers/TicketProvider";
import { createTicket } from "../../../api/tickets";
import { format, add } from "date-fns";
import HeaderComponent from "../../components/Header/HeaderComponent";
import { useProperty } from "../../../providers/PropertyProvider";
import SuccessMessageComponent from "./SuccessMessageComponent";
import BusyIndicatorComponent from "../../components/BusyIndicator/BusyIndicatorComponent";

export default function ScheduleVisit(props: any) {
  const { scheduleId } = props;
  const params = {
    device: "web",
  };

  const { scheduleType } = useParams();

  const isRescheduling = scheduleType === 'reschedule';

  const { getOwnerToken, getTenantToken } = useAuth();
  const { getTicket } = useTicket();
  const { getPropertyFromProvider } = useProperty();

  const [dayPicked, setDayPicked] = useState("");
  const [hourPicked, setHourPicked] = useState("");
  const [modulePicked, setModulePicked] = useState(0);
  const [datePicked, setDatePicked] = useState("");
  const [resultsList, setResultsList] = useState<any[]>([]);
  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [isFirstEntrance, setIsFirstEntrance] = useState(true);
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [serviceResponse, setServiceResponse] = useState<any>({});
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [openMessage, setOpenMessage] = useState(false);

  const navigate = useNavigate();
  const ticket = getTicket();
  const userProperty = getPropertyFromProvider();

  useEffect(() => {
    if (resultsList.length) {
      setMinDate(format(add(resultsList[0].date, { days: 1 }), "yyyy-MM-dd"));
      setMaxDate(format(add(resultsList[0].date, { days: 14 }), "yyyy-MM-dd"));
    }
  }, [resultsList]);

  const callForScheduleVisit = async () => {
    if (!getOwnerToken() && !getTenantToken()) {
      navigate("/error/403");
    }
    if (isRescheduling && userProperty.date)
      setDatePicked(format(userProperty.date, "MMM d, yyyy"));
    const response = await getAvailableHours(
      String(
        (ticket.location !== undefined ? String(ticket.location) : "") ||
          (userProperty.id !== undefined ? String(userProperty.id) : "") ||
          ""
      ),
      getTenantToken() || getOwnerToken()
    );
    if (
      response.status === 'failed'
    ) {
      return navigate('/error/500');
    }
    if ( response.status === 'unauthorized' ) {
      return navigate('/error/403');
    }
    setResultsList(response);
    setLoadingComponent(false);
  };

  useEffect(() => {
    setLoadingComponent(true);
    callForScheduleVisit();
  }, []);

  const handlePickedHour = (day: any, hour: any) => {
    setDayPicked(day.date);
    setModulePicked(hour.id);
    setHourPicked(hour.hours);
    setOpenMessage(true);
  };

  const resetScheduleValues = () => {
    setDayPicked("");
    setModulePicked(0);
    setHourPicked("");
    setDatePicked("");
    callForScheduleVisit();
  };

  const handleCloseMessageBox = (event: CustomEvent<{ action: string }>) => {
    setOpenMessage(false);
    const newDay = new Date(dayPicked);
    const desplazamientoHorario = newDay.getTimezoneOffset();
    const fechaLocal = new Date(newDay.getTime() + (desplazamientoHorario * 60000));
    if (event.detail.action === "1: custom action") {
      processScheduling(fechaLocal);
    } else {
      resetScheduleValues();
    }
  };

  const processScheduling = async (day: Date) => {
    setLoadingComponent(true);
    if (isRescheduling) {
      await scheduleHour({ticket: getTicket().code, module: modulePicked, date: day.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })}, getTenantToken() || getOwnerToken()).then(response => processResponse(response, day));
    } else {
      createTicket(
        {
          ...getTicket(),
          visit: { module: modulePicked, date: format(day, "dd/MM/yyyy") },
        },
        getTenantToken() || getOwnerToken() || ""
      ).then(response => processResponse(response));
    }
  };

  const processResponse = (response: any, day?: any) => {
    setLoadingComponent(false);
    if (
      response.status === 'failed'
    ) {
      return setFailedMessage(response.reason);
    }
    if ( response.status === 'unauthorized' ) {
      return navigate('/error/403');
    }
    const reschedulingResponse = {
      code: getTicket().code,
      visit: {
        date: day?.toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }),
        module: response.hours,
      }
    }
    setServiceResponse(isRescheduling ? reschedulingResponse : response);
    setHourPicked("");
    setIsSuccesful(true);
    setLoadingComponent(false);
  }

  useEffect(() => {
    if (datePicked && !isFirstEntrance) {
      const formattedDate = formatDate(datePicked);
      setResultsList(
        resultsList.filter((date: { date: string }) => {
          return date.date === formattedDate;
        })
      );
    }
    setIsFirstEntrance(false);
  }, [datePicked]);

  const handleChangeDate = (
    e: Ui5CustomEvent<DatePickerDomRef, DatePickerChangeEventDetail>
  ) => {
    setDatePicked(e.detail.value);
  };

  return (
    <>
      {params.device === "web" && (
        <>
          {isSuccesful && (<SuccessMessageComponent
            ticketNumber={serviceResponse.code}
            dayPicked={serviceResponse.visit.date}
            hourPicked={`${serviceResponse.visit.module}`}
            isOwner={!!getOwnerToken()}
            isRescheduling={isRescheduling}
          />)}
          <div className="m-20"
              style={{ opacity: loadingComponent ? 0.5 : 1 }}>
            <Card className="cards-background">
              <HeaderComponent></HeaderComponent>
              <BusyIndicatorComponent loading={loadingComponent} />
              <div className="draaft-front__landing-card__header m-3">
                <Title level="H2">{scheduleType === 'reschedule' ? 'REAGENDAR VISITA' : 'AGENDAR VISITA'}</Title>
              </div>
              <div className="m-3">
                <Title level="H6">
                  Seleccione la fecha y rango horario para su visita
                </Title>
                <p className="text-xs">Disponibilidad sujeta a confirmación.</p>
              </div>
              <div className="draaft-front__landing-card__header m-3">
                <DatePicker
                  value={datePicked}
                  onChange={(e) => handleChangeDate(e)}
                  maxDate={maxDate}
                  minDate={minDate}
                ></DatePicker>
                <Button onClick={resetScheduleValues}>Limpiar</Button>
              </div>
            </Card>
          {failedMessage && (
            <div className="m-2">
              <MessageStrip design="Negative" onClose={() => setFailedMessage('')}>
                {failedMessage}
              </MessageStrip>
            </div>
          )}
            <div className="m-1">
              <p className="font-bold">Próximas horas disponibles:</p>
            </div>
            <div className="mt-0 bottom-div mr-10 mt-15">
              <List>
                {(resultsList as any)?.map(
                  (day: any) =>
                    day.modules.map((hour: any) => (
                      <StandardListItem
                        key={`${day.date}_${hour.id}`}
                        className="w-full"
                        description={hour.hours}
                        onClick={() => handlePickedHour(day, hour)}
                      >
                        {formatDateToISO(day.date)}
                      </StandardListItem>
                    )) || []
                )}
              </List>
            </div>
            {resultsList.length === 0 && (
              <MessageStrip design="Negative" onClose={function _a() {}}>
                No hay horarios disponibles con la búsqueda realizada.
              </MessageStrip>
            )}
          </div>

          <MessageBox
            onClose={(e) => handleCloseMessageBox(e)}
            actions={[
              <Button design="Negative">Cancelar</Button>,
              <Button design="Positive">Aceptar</Button>,
            ]}
            type="Confirm"
            open={openMessage}
          >
            ¿Seguro que desea confirmar el horario de{" "}
            <strong>{formatDateToISO(dayPicked)}</strong> entre las{" "}
            <strong>{hourPicked}</strong>?
          </MessageBox>
        </>
      )}
    </>
  );
}
