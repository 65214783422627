import { ERROR_SCHEDULING_HOUR, ERROR_SERVICE, ERROR_UNAUTHORIZED, LOGIN_URL } from "../constants/constants";

export const getAvailableHours = async (user: string, token: string | null) => {
    try {
        if (!token) {
            return ERROR_UNAUTHORIZED;
        }
        const response = await fetch(`${LOGIN_URL}/visit/available/${user}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
        });
        if (!response.ok) {
          return ERROR_SERVICE;
        }
        return await response.json();
    }
    catch(e) {
        return ERROR_SERVICE;
    }
}

export const scheduleHour = async (body: any, token: string | null) => {
    try {
        if (!token) {
            return ERROR_UNAUTHORIZED;
        }
        const response = await fetch(`${LOGIN_URL}/visit/reprogram`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
          return ERROR_SCHEDULING_HOUR;
        }
        return await response.json();
    }
    catch (e) {
        return ERROR_SCHEDULING_HOUR;
    }
}