import {
  Button,
  Dialog,
  Bar,
  Label,
  Table,
  TableCell,
  TableColumn,
  TableRow,
} from "@ui5/webcomponents-react";
import React, { useRef } from "react";
import { createPortal } from "react-dom";

export default function RemarksListModal(props: any) {
  const { remarksList, setSelectedRemarks } = props;
  const dialogRef = useRef<any>(null);
  const defaultImage = 'sin-imagen.jpg'
  const onButtonClick = () => {
    setSelectedRemarks(remarksList);
    if (dialogRef.current) {
      dialogRef.current?.show();
    }
  };
  const handleClose = () => {
    setSelectedRemarks({});
    dialogRef.current.close();
  };
  return (
    <>
      <Button
        className="bg-gray-500 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded mr-2"
        id={"openPopoverBtn"}
        onClick={() => {
          onButtonClick();
        }}
      >
        Observaciones
      </Button>
      {createPortal(
        <Dialog
          className="footerPartNoPadding"
          ref={dialogRef}
          footer={
            <Bar
              design="Footer"
              endContent={
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cerrar
                </Button>
              }
            />
          }
          headerText="Dialog Header"
          onAfterOpen={function _a() {}}
          onBeforeClose={function _a() {}}
          onBeforeOpen={function _a() {}}
        >
          <Table
            columns={
              <>
                <TableColumn>
                  <Label>Lugar</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Comentario</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Estado</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Imágenes</Label>
                </TableColumn>
              </>
            }
          >
            {remarksList.map((remark: any, index: number) => (
              <TableRow key={`${index} - ${remark.site}`}>
                <TableCell>
                  <Label>{remark.site}</Label>
                </TableCell>
                <TableCell>
                  <Label>{remark.remark}</Label>
                </TableCell>
                <TableCell>
                  <Label>{remark.status}</Label>
                </TableCell>
                <TableCell className="w-32 h-32">
                  {remark.images.length > 0 &&
                    remark.images.map((image: string) => {
                      return <img
                        className="w-full h-full object-cover"
                        src={image}
                      />;
                    })}
                  {remark.images.length === 0 && (<img
                        className="w-full h-full object-cover"
                        src={defaultImage}
                      />)}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Dialog>,
        document.body
      )}
    </>
  );
}
