import { ERROR_CHANGING_PASSWORD, ERROR_SERVICE, ERROR_UNAUTHORIZED, ERROR_UPDATING_MAIL, ERROR_UPDATING_USER, LOGIN_URL } from "../constants/constants";

export const getUserData = async (token: string | null) => {
    try {
        if (!token) {
            return ERROR_UNAUTHORIZED;
        }
        const response = await fetch(`${LOGIN_URL}/user`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
        });
        if (!response.ok) {
          return ERROR_SERVICE;
        }
        return await response.json();
    }
    catch(e) {
        return ERROR_SERVICE;
    }
}

export const updateUserData = async (token: string | null, body: any) => {
    try {
        if (!token) {
            return ERROR_UNAUTHORIZED;
        }
        const response = await fetch(`${LOGIN_URL}/user`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
          return ERROR_UPDATING_USER;
        }
        // if (response.code === 'usr001') return ERROR_UPDATING_MAIL;
        return await response.json();
    }
    catch(e) {
        return ERROR_UPDATING_USER;
    }
}

export const changePassword = async (token: string | null, body: any) => {
    try {
        if (!token) {
            return ERROR_UNAUTHORIZED;
        }
        const response = await fetch(`${LOGIN_URL}/auth/password`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
          return ERROR_CHANGING_PASSWORD;
        }
        return await response.json();
    }
    catch(e) {
        return ERROR_CHANGING_PASSWORD;
    }
}
