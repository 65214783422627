import {
  Card,
  Title,
  Input,
  Button,
  Ui5CustomEvent,
  InputDomRef,
  MessageStrip,
} from "@ui5/webcomponents-react";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../providers/AuthProvider";
import { validateEmail } from "../../../utils/email";
import { useRut } from "../../../utils/react-rut-formatter";
import "react-international-phone/style.css";
import { getTenantsLogin } from "../../../api/login";
import { useNavigate } from "react-router-dom";
import { validatePhone } from "../../../utils/phone";
import BusyIndicatorComponent from "../../components/BusyIndicator/BusyIndicatorComponent";
import ReCAPTCHA from "react-google-recaptcha";

export default function SignUpFormComponent() {
  const { clearAllToken, setTenantToken } = useAuth();
  const { rut, updateRut, isValid } = useRut();
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [validateAllFields, setValidateAllFields] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const siteKeyForDev = '6Ld6QZUpAAAAAN-38rGx79eMsqXKrSJ0I9gnRGBp';
  const [captchaValue, setReCaptchaValue] = useState(null);
  const navigate = useNavigate();
  clearAllToken();
  const params = {
    device: "web",
  };

  const handleUpdateRut = (e: Ui5CustomEvent<InputDomRef, never>) =>
    updateRut(e.target.value || "");

  const handleNameChange = (e: Ui5CustomEvent<InputDomRef, never>) =>
    setFullName(e.target.value || "");

  const handleNumberChange = (e: Ui5CustomEvent<InputDomRef, never>) => {
    setPhone(e.target.value || "");
  };

  useEffect(() => {
    setIsValidPhone(!!validatePhone(phone || ""));
  }, [phone]);

  const handleEmailChange = (e: Ui5CustomEvent<InputDomRef, never>) =>
  {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(e.target.value));
    setEmail(e.target.value || "");
  }

  const handleSavingTenant = async () => {
    setValidateAllFields(false);
    if (isValid && isValidPhone && fullName !== "" && validateEmail(email) && captchaValue) {
      setLoadingContent(true);
      const response = await getTenantsLogin({
        dni: rut.raw,
        name: fullName,
        phone,
        mail: email,
      }, captchaValue);
      setLoadingContent(false);
      if (response.status === "failed") {
        return setFailedMessage(response.reason);
      }
      if (response.status === "unauthorized") {
        return navigate("/error/403");
      }
      setTenantToken(response.accessToken);
      navigate("/send-ticket");
      if (
        !response ||
        response.statusCode === 500 ||
        response.statusCode === 400 ||
        response.code === "auth001"
      ) {
        navigate("/send-ticket");
      }
    } else {
      setValidateAllFields(true);
    }
    if (!captchaValue) setFailedMessage('Captcha inválido. Por favor, reintentar');
  };

  const handleCaptchaValue = (value: any) => {
    setFailedMessage('');
    setReCaptchaValue(value);
  }

  useEffect(() => {
    setIsDisabled(
      rut.raw === "" || fullName === "" || phone === "" || email === ""
    );
  }, [rut, fullName, phone, email]);

  return (
    <>
      {params.device === "web" && (
        <>
          <div className="m-20">
            <div className="draaft-front__landing-card__header m-3">
              <img
                slot="logo"
                src="gpr-logo.jpeg"
                className="draaft-front-login-image"
                alt=""
              />
            </div>
            <Card
              className="ml-1 mt-6 cards-background"
              style={{ opacity: loadingContent ? 0.5 : 1 }}
            >
              <BusyIndicatorComponent loading={loadingContent} />
              <div className="m-3">
                <Title
                  level="H3"
                  className="draaft-front__landing-card__header"
                >
                  FORMULARIO DE INGRESO PARA RESIDENTES (NO PROPIETARIOS)
                </Title>
              </div>
              <div className="mt-5 ml-2 mr-3">
                <Input
                  id="input-6"
                  className="draaft-front__login-inputs m-1 w-full"
                  value={rut.formatted}
                  placeholder="RUT"
                  onChange={handleUpdateRut}
                  valueState={validateAllFields && !isValid ? "Error" : "None"}
                  required
                />
                {validateAllFields && !isValid && (
                  <p className="ml-1 text-red-600">
                    El rut ingresado no es válido
                  </p>
                )}
                <Input
                  id="input-6"
                  className="draaft-front__login-inputs m-1 w-full"
                  placeholder="Nombre completo"
                  onChange={handleNameChange}
                  type="Text"
                  valueState={
                    validateAllFields && fullName === "" ? "Error" : "None"
                  }
                  required
                />
                {validateAllFields && fullName === "" && (
                  <p className="ml-1 text-red-600">
                    Debe completar con un nombre válido
                  </p>
                )}
                <Input
                  className="draaft-front__login-inputs m-1 w-full"
                  placeholder="Teléfono en formato 9XXXXXXXX o 22XXXXXXX"
                  value={phone}
                  onChange={(e) => handleNumberChange(e)}
                  type="Number"
                  valueState={
                    validateAllFields && !isValidPhone ? "Error" : "None"
                  }
                  maxlength={9}
                />
                {validateAllFields && !isValidPhone && (
                  <p className="ml-1 text-red-600">
                    El teléfono ingresado no cumple con el formato especificado
                    (9XXXXXXXX o 22XXXXXXX).
                  </p>
                )}
                <Input
                  id="input-6"
                  className="draaft-front__login-inputs m-1 w-full"
                  placeholder="Correo electrónico"
                  onChange={handleEmailChange}
                  valueState={
                    validateAllFields && !validateEmail(email)
                      ? "Error"
                      : "None"
                  }
                  required
                />
                {validateAllFields && !isValidEmail && (
                  <p className="ml-1 text-red-600">
                    El mail ingresado no cumple con el formato xxxx@xxxx.xxx
                  </p>
                )}
              </div>
              {failedMessage && (
                <div className="m-2">
                  <MessageStrip
                    design="Negative"
                    onClose={() => setFailedMessage("")}
                  >
                    {failedMessage}
                  </MessageStrip>
                </div>
              )}
              <div className="draaft-front__landing-card__buttons flex flex-col h-full justify-center space-between items-center mt-6 ml-3 mr-3 mb-3">
                <Button
                  design="Default"
                  onClick={() => navigate("/login")}
                  className="bg-gray-500 hover:bg-gray-300 hover:text-black text-white px-4 py-2 mb-2 w-full"
                >
                  Cancelar
                </Button>
                <Button
                  design="Default"
                  onClick={handleSavingTenant}
                  disabled={isDisabled}
                  className="affirmative-button hover:bg-blue-700 text-white px-4 py-2 mb-2 w-full"
                >
                  Guardar
                </Button>
              </div>
            <div className="m-5 flex flex-col h-full justify-center items-center">
              <ReCAPTCHA
                sitekey={siteKeyForDev}
                onChange={handleCaptchaValue}
              />
            </div>
            </Card>
          </div>
        </>
      )}
    </>
  );
}
