import {
  Card,
  Panel,
  Title,
  Text,
  Input,
  Ui5CustomEvent,
  InputDomRef,
  Button,
  MessageStrip,
} from "@ui5/webcomponents-react";
import { first } from "lodash";
import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../api/user";
import BusyIndicatorComponent from "../../components/BusyIndicator/BusyIndicatorComponent";
import HeaderComponent from "../../components/Header/HeaderComponent";
import SuccesfulMessagePasswordComponent from "./SuccesfulMessagePasswordComponent";

export default function ChangePasswordComponent(props: any) {
  const { token } = props;
  const [loadingContent, setLoadingContent] = useState(false);
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [firstPassword, setFirstPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    if (firstPassword === secondPassword) {
      setLoadingContent(true);
      const response = await changePassword(token, {
        password: firstPassword,
        repeatPassword: secondPassword,
      });
      if (response.status === "failed") {
        return setFailedMessage(response.reason);
      }
      if (response.status === "unauthorized") {
        return navigate("/error/403");
      }
      setLoadingContent(false);
      setIsSuccesful(true);
    } else {
      setFailedMessage("Las contraseñas no son iguales.");
    }
  };

  const succesfulMessageProps = {
    title: "Cambio de contraseña exitoso.",
    textContent:
      "Ya puede volver a operar en el sitio con su nueva contraseña.",
    buttonText: "Volver al inicio",
  };

  return (
    <>
      <div className="m-20">
        {isSuccesful && <SuccesfulMessagePasswordComponent props={props} />}
        <Card
          className="cards-background"
          style={{ opacity: loadingContent ? 0.5 : 1 }}
        >
          <BusyIndicatorComponent loading={loadingContent} />
          <HeaderComponent></HeaderComponent>
          <div className="mt-5 ml-2 mr-3">
            <Title level="H3" className="m-1">
              Cambio de contraseña
            </Title>
          </div>
          <div className="mt-5 ml-2 mr-3">
            <Input
              id="input-6"
              className="draaft-front__login-inputs m-1 w-full"
              value={firstPassword}
              onChange={(e) => setFirstPassword(e.target.value || "")}
              placeholder="Nueva contraseña"
              required
            />
            <Input
              id="input-7"
              className="draaft-front__login-inputs m-1 w-full"
              placeholder="Contraseña"
              value={secondPassword}
              required
              type="Password"
              onChange={(e) => setSecondPassword(e.target.value || "")}
            />
          </div>
          {failedMessage && (
            <div className="m-2">
              <MessageStrip
                design="Negative"
                onClose={() => setFailedMessage("")}
              >
                {failedMessage}
              </MessageStrip>
            </div>
          )}
          <div className="draaft-front__landing-card__buttons flex flex-col h-full justify-center items-center ml-2 mr-2 mt-4 mb-6">
            <Button
              onClick={(e) => handleSubmit(e)}
              design="Default"
              disabled={!firstPassword && !secondPassword}
              className="affirmative-button text-white px-4 py-2 mb-2 w-full"
            >
              Generar nueva contraseña
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
