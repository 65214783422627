import { Button, Card, Title } from "@ui5/webcomponents-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";
import { useProperty } from "../../../providers/PropertyProvider";
import { useTicket } from "../../../providers/TicketProvider";

export default function ForbiddenComponent() {
  const { clearAllToken } = useAuth();
  const { clearTicket } = useTicket();
  const { clearProperty } = useProperty();
  clearAllToken();
  clearTicket();
  clearProperty();
  const navigate = useNavigate();
  const handleRedirection = (link: string) => {
    navigate(link);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card className="m-20">
        <div className="draaft-front__landing-card__header m-3">
          <img
            slot="logo"
            src="/gpr-logo.jpeg"
            className="draaft-front-login-image"
            alt="image"
          />
        </div>
        <div className="draaft-front__landing-card__header m-3">
          <Title level="H2" className="mb-10">
            No está identificado o sus credenciales están vencidas.
          </Title>
        </div>
        <div className="m-2">
          <Button
            design="Default"
            onClick={() => handleRedirection("/login")}
            className="bg-green-500 text-white mb-2 w-full"
          >
            Volver a autenticación
          </Button>
        </div>
      </Card>
    </div>
  );
}
