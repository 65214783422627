export const LOGIN_URL = 'https://gpr-backend.dominioagil.cl/front-office/postventa/api';

export const MAX_IMAGES_PER_OBSERVATION = 3;

export const ERROR_SERVICE = {
    response: null,
    status: 'unavailable',
}

export const ERROR_UNAUTHORIZED = {
    response: null,
    status: 'unauthorized',
}

export const ERROR_LOGIN = {
    response: null,
    status: 'failed',
    reason: 'Usuario o contraseña son incorrectos.',
}

export const ERROR_RECOVERING = {
    response: null,
    status: 'failed',
    reason: 'Hubo un error en el servicio, o el usuario ingresado no se encuentra en nuestra base de datos.',
}

export const ERROR_LOGIN_TENANTS = {
    response: null,
    status: 'failed',
    reason: 'No se pudo registrar al usuario. Por favor, reintente.',
}

export const ERROR_CREATING_TICKET = {
    response: null,
    status: 'failed',
    reason: 'No se pudo crear el ticket. Por favor, reintente.',
}

export const ERROR_UPDATING_USER = {
    response: null,
    status: 'failed',
    reason: 'No se han podido actualizar los datos del usuario. Por favor, reintente.',
}

export const ERROR_SCHEDULING_HOUR = {
    response: null,
    status: 'failed',
    reason: 'No se ha podido agendar la hora correctamente. Por favor, reintente.',
}

export const ERROR_CHANGING_PASSWORD = {
    response: null,
    status: 'failed',
    reason: 'No se ha podido cambiar la contraseña. Por favor, reintente.',
}

export const ERROR_UPDATING_MAIL = {
    response: null,
    status: 'failed',
    reason: 'No se ha podido actualizar el mail, ya que está registrado a nombre de otro usuario.',
}