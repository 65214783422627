import { Button, Title } from "@ui5/webcomponents-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SuccesfulMessagePasswordComponent(props: any) {
  const { title, textContent, buttonText } = props;
  const navigate = useNavigate();
  const handleRedirection = () => {
    navigate("/login");
  };
  return (
    <div className="success-message">
      <div className="draaft-front__landing-card__header m-5">
        <img
          slot="logo"
          src="/gpr-logo.jpeg"
          className="draaft-front-login-image"
          alt=""
        />
      </div>
      <Title level="H2">{title}</Title>
      <p style={{ textAlign: "center" }}>{textContent}</p>
      <Button
        className="affirmative-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5"
        onClick={() => handleRedirection()}
      >
        {buttonText}
      </Button>
    </div>
  );
}
