import React from 'react';

const Footer = () => {
    return (
        <footer className="mt-50 fixed bottom-0 w-full bg-gray-200 py-4 text-center">
            <p className="text-sm text-gray-600">Dominio Ágil, 2024</p>
        </footer>
    );
};

export default Footer;