import { BusyIndicator } from "@ui5/webcomponents-react";
import React from "react";

export default function BusyIndicatorComponent(props: any) {
  const { loading } = props;
  return (
    <BusyIndicator
      active={loading}
      size="Large"
      text="Cargando..."
      delay={10}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        opacity: !loading ? 0.5 : 1,
      }}
    />
  );
}
