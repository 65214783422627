import React from "react";
import { useParams } from "react-router-dom";
import ForbiddenComponent from "./components/ForbiddenComponent";
import NotFoundComponent from "./components/NotFoundComponent";
import UnavailableServiceComponent from "./components/UnavailableService";

export default function Errors () {
    const { errorType } = useParams();
    return (
        <> 
            {errorType === '403' && (
                <ForbiddenComponent />
            )}
            {errorType === '500' && (
                <UnavailableServiceComponent />
            )}
            {errorType !== '403' && errorType !== '500' && (
                <NotFoundComponent />
            )}
        </>
    );
}