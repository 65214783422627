import React, { FormEvent, useEffect } from "react";
import getLogin from "../../../api/login";
import {
  Card,
  Title,
  Button,
  Input,
  MessageStrip,
  InputDomRef,
  Ui5CustomEvent,
} from "@ui5/webcomponents-react";
import { useState } from "react";
import { useRut } from "../../../utils/react-rut-formatter";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { useAuth } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import BusyIndicatorComponent from "../../components/BusyIndicator/BusyIndicatorComponent";
import { useTicket } from "../../../providers/TicketProvider";
import { useProperty } from "../../../providers/PropertyProvider";

export default function LoginComponent() {
  const navigate = useNavigate();
  const { rut, updateRut } = useRut();
  const [password, setPassword] = useState("");
  const [failedMessage, setFailedMessage] = useState('');
  const { setOwnerToken, clearAllToken } = useAuth();
  const { clearTicket } = useTicket();
  const { clearProperty } = useProperty();
  const [isDisabled, setIsDisabled] = useState(true);
  const [loadingContent, setLoadingContent] = useState(false);

  clearAllToken();
  clearTicket();
  clearProperty();

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    setFailedMessage('');
    setLoadingContent(true);
    const response = await getLogin({ dni: rut.raw, password });
    setLoadingContent(false);
    if (
      response.status === 'failed'
    ) {
      return setFailedMessage(response.reason);
    }
    if (!response.statusCode && !response.code) {
      setOwnerToken(response.accessToken);
      navigate("/personal-data");
    }
  };

  useEffect(() => {
    setIsDisabled(rut.raw === "" || password === "");
  }, [rut, password]);

  const handleUpdateRut = (e: Ui5CustomEvent<InputDomRef, never>) =>
    updateRut(e.target.value || "");

  const handleSetPassword = (e: Ui5CustomEvent<InputDomRef, never>) =>
    setPassword(e.target.value || "");

  const handleNewUser = () => navigate("/signup-form");

  const handleRecoverPass = () => navigate("/recover-pass/new-mail");

  return (
    <>
      <div className="m-20">
        <div className="draaft-front__landing-card__header m-3">
          <img
            slot="logo"
            src="gpr-logo.jpeg"
            className="draaft-front-login-image"
            alt=""
          />
        </div>
        <div className="draaft-front__landing-card__header m-3">
          <Title level="H2">MI HOGAR GPR</Title>
        </div>
        <Card
          className="ml-1 mt-6 cards-background"
          style={{ opacity: loadingContent ? 0.5 : 1 }}
        >
          <BusyIndicatorComponent loading={loadingContent} />
          <div className="mt-5 ml-2 mr-3">
            <Input
              id="input-6"
              className="draaft-front__login-inputs m-1 w-full"
              value={rut.formatted}
              onChange={(e) => handleUpdateRut(e)}
              placeholder="RUT"
              required
            />
            <Input
              id="input-7"
              className="draaft-front__login-inputs m-1 w-full"
              placeholder="Contraseña"
              required
              type="Password"
              onChange={(e) => handleSetPassword(e)}
            />
          </div>
          <div className="draaft-front__landing-card__buttons flex flex-col h-full justify-center items-center ml-2 mr-2 mt-4 mb-6">
            <Button
              onClick={(e) => handleSubmit(e)}
              design="Default"
              disabled={isDisabled}
              className="affirmative-button text-white px-4 py-2 mb-2 w-full ml-1"
            >
              Ingresar
            </Button>
          </div>
          {failedMessage && (
            <div className="m-2">
              <MessageStrip design="Negative" onClose={() => setFailedMessage('')}>
                {failedMessage}
              </MessageStrip>
            </div>
          )}
          <p className="ml-3 mt-3">
            ¿No es propietario y desea generar un ticket?
          </p>
          <div className="mt-2 ml-3 mb-5">
            <Button
              className="bg-green-700 text-white mr-3"
              onClick={handleNewUser}
            >
              Ingrese aquí
            </Button>
            <Button onClick={handleRecoverPass}>Recuperar clave</Button>
          </div>
        </Card>
      </div>
    </>
  );
}
