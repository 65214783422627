import React, { createContext, useContext, useEffect, useState } from 'react';

interface PropertyProviderType {
    property: any,
    getPropertyFromProvider: () => any,
    setProperty: (Property: any) => void,
    clearProperty: () => any,
}

const PropertyContext = createContext<PropertyProviderType>({
    property: {},
    getPropertyFromProvider: () => null,
    setProperty: () => {},
    clearProperty: () => null
});

export const PropertyProvider: React.FC = ({ children }: any) => {
  const [property, setProperty] = useState<any>(() => {
    const storedProperty = localStorage.getItem('property');
    return storedProperty ? JSON.parse(storedProperty) : {};
});

  useEffect(() => {
    if (property !== null && property !== undefined) {
        localStorage.setItem('property', JSON.stringify(property));
    } else {
        localStorage.removeItem('property');
    }
}, [property]);

  const getPropertyFromProvider = () => {
    return property;
  };

  const clearProperty = () => {
    setProperty(null);
    localStorage.removeItem('property');
  }

  return (
    <PropertyContext.Provider value={{ property, getPropertyFromProvider, setProperty, clearProperty }}>
      {children}
    </PropertyContext.Provider>
  );
};

export const useProperty = (): PropertyProviderType => useContext(PropertyContext);