import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import React, { useRef, useEffect } from "react";
import Landing from "./landing";
import Login from "./login";
import ScheduleVisit from "./schedule-visit";
import PersonalData from "./personal-data";
import SignUpForm from "./signup-form";
import SendTicket from "./send-ticket";
import RecoverPass from "./recover-pass";
import Footer from "./components/Footer/Footer";
import Errors from "./errors";
import NotFound from "./not-found";
import { AuthProvider } from "../providers/AuthProvider";
import { TicketProvider } from "../providers/TicketProvider";
import { PropertyProvider } from "../providers/PropertyProvider";

function App() {
  const lastElementRef = useRef(null);

  useEffect(() => {
    const lastElementPosition =
      lastElementRef.current.getBoundingClientRect().bottom;
    const footerPosition = window.innerHeight - lastElementPosition;
    const minMarginBottom = 50;
    const marginBottom = Math.max(minMarginBottom, 50 - footerPosition);

    if (marginBottom > minMarginBottom) {
      lastElementRef.current.style.marginBottom = `${marginBottom}px`;
    }
  }, []);
  return (
    <>
      <AuthProvider>
        <TicketProvider>
          <PropertyProvider>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />}></Route>
              <Route path="/landing" element={<Landing />}></Route>
              <Route
                path="/schedule-visit/:scheduleType"
                element={<ScheduleVisit />}
              ></Route>
              <Route path="/personal-data" element={<PersonalData />}></Route>
              <Route path="/signup-form" element={<SignUpForm />}></Route>
              <Route path="/send-ticket" element={<SendTicket />}></Route>
              <Route path="/recover-pass/:token" element={<RecoverPass />}></Route>
              <Route path="/error/:errorType" element={<Errors />}></Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
            <div ref={lastElementRef}>&nbsp;</div>
            <Footer />
          </PropertyProvider>
        </TicketProvider>
      </AuthProvider>
    </>
  );
}

export default App;
