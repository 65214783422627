import { MAX_IMAGES_PER_OBSERVATION } from "../constants/constants";

export const handleImagesArray = (
  files: FileList,
  fileNames: string,
  loadedImages: number,
): { files: FileList; fileNames: string[] } => {
  let entries = Object.entries(files);
  if (entries.length > MAX_IMAGES_PER_OBSERVATION) {
    entries = entries.slice(0, 3 - loadedImages);
  }

  let newObj: { [key: number]: File } = {};
  entries.forEach(([_, value], index) => {
    newObj[index] = value;
  });

  let filesArray = Object.values(newObj);

  const dataTransfer = new DataTransfer();
  filesArray.forEach((file: File) => dataTransfer.items.add(file));

  let sanitizedFileNames: string[] = [];

  if (fileNames.includes('" "')) {
    sanitizedFileNames = fileNames.split('" "').map(name => name.replace(/"/g, ''))
  } else {
    sanitizedFileNames = [fileNames.replace(/"/g, '')];
  }

  sanitizedFileNames = sanitizedFileNames.slice(0, 3 - loadedImages);

  return {
    files: dataTransfer.files,
    fileNames: sanitizedFileNames,
  };
};
