import { ShellBar } from "@ui5/webcomponents-react";
import React from "react";

export default function TopBar () {
    return (
        <> 
            <ShellBar>
                <img slot="logo" src="../gpr-logo.jpeg" alt=""/>
            </ShellBar>
        </>
    );
}