export default function formatDate(inputDate: string): string {
    const monthsMap: { [key: string]: number } = {
      Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
      Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
    };
  
    const parts = inputDate.split(' ');
    const year = parseInt(parts[2], 10);
    const month = monthsMap[parts[0]];
    const day = parseInt(parts[1].replace(',', ''), 10);
  
    const date = new Date(year, month, day);
  
    const isoDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}T00:00:00.000Z`;
  
    return isoDate;
}

export function formatDateToISO (date: string): string {
  const dateParts = date.split('T')[0].split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;
  const day = parseInt(dateParts[2]);

  return new Date(year, month, day).toLocaleDateString('es-CL', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
  });
}

export function formatDateToDDmmYYYY (date: string) {
  const dateParts = date.split('T')[0].split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;
  const day = parseInt(dateParts[2]);
  return `${day}/${month}/${year}`;
}