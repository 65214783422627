import { Button, Card, Link, Title } from '@ui5/webcomponents-react';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import TopBar from '../../components/TopBar/TopBar';
import { useAuth } from '../../../providers/AuthProvider';

export default function LandingComponent () {
    const { getOwnerToken } = useAuth();
    const navigate = useNavigate();
    const params = {
        device: 'web',
    };

    useEffect(() => {
        if (!getOwnerToken()) {
            return navigate('/error/403');
        }
    }, []);

    const handleRedirection = (link: string) => {
        navigate(link);
    }

    return (
        <>
            {params.device === 'web' && (
                <>

                <img src="../..//../components/gpr-logo.jpeg" alt=""/>
                <TopBar></TopBar>
                <div className="m-20">
                    <Card className="m-1">
                        <div className="draaft-front__landing-card__header m-3">
                            <Title level="H3">Sistema de Postventa</Title>
                        </div>
                        <div className="m-3">
                            <p>GPR, preocupado por sus clientes, pone a disposición una plataforma para registrar y dar seguimiento a sus solicitudes de postventa.</p>
                        </div>
                        <div className="draaft-front__landing-card__buttons flex flex-col h-full justify-center items-center m-3">
                            <Button design="Default" onClick={() => handleRedirection('#')} className="bg-green-500 text-white mb-2 w-full">Mi Hogar GPR</Button>
                            <Button design="Default" onClick={() => handleRedirection('#')} className="bg-green-700 text-white mb-2 w-full">Ingresar nueva solicitud</Button>
                            <Button design="Default" onClick={() => handleRedirection('#')} className="bg-yellow-500 text-black mb-2 w-full">Revisar estado de solicitud</Button>
                            <Button design="Default" onClick={() => handleRedirection('/schedule-visit/reschedule')} className="bg-orange-500 text-black mb-2 w-full">Reagendar Visita</Button>
                        </div>
                    </Card>
                </div>
                </>
                )}
                {params.device === 'phone' && (
                    <>
                    <TopBar></TopBar>
                    <div className="m-10">
                    <Card className="m-1">
                        <div className="draaft-front__landing-card__header m-3">
                            <Title level="H3">Sistema de Postventa</Title>
                        </div>
                        <div className="m-3">
                            <p>GPR, preocupado por sus clientes, pone a disposición una plataforma para registrar y dar seguimiento a sus solicitudes de postventa.</p>
                        </div>
                        <div className="draaft-front__landing-card__buttons flex flex-col h-full justify-center items-center m-3">
                            <Button design="Default" className="bg-green-500 text-white mb-2 w-full"><Link href="/login">Mi Hogar GPR</Link></Button>
                            <Button design="Default" className="bg-green-700 text-white mb-2 w-full">Ingresar nueva solicitud</Button>
                            <Button design="Default" className="bg-yellow-500 text-black w-full">Revisar estado de solicitud</Button>
                        </div>
                    </Card></div>
                    </>
                    )}
        </>
    )
};