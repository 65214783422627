import {
  ERROR_LOGIN,
  ERROR_LOGIN_TENANTS,
  ERROR_RECOVERING,
  LOGIN_URL,
} from "../constants/constants";
import { LoginData } from "../types/login";

export const getLogin = async (body: LoginData) => {
  try {
    const response = await fetch(`${LOGIN_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      return ERROR_LOGIN;
    }
    return await response.json();
  } catch (e) {
    return ERROR_LOGIN;
  }
};

export const recoverPassword = async (body: { dni: string }, recaptchaToken: string) => {
  try {
    const response = await fetch(`${LOGIN_URL}/auth/recovery`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "recaptcha-token": recaptchaToken,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      return ERROR_RECOVERING;
    }
    return await response.json();
  } catch (e) {
    return ERROR_RECOVERING;
  }
};

export const getTenantsLogin = async (body: any, captchaValue: string) => {
  try {
    const response = await fetch(`${LOGIN_URL}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "recaptcha-token": captchaValue,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      return ERROR_LOGIN_TENANTS;
    }
    return await response.json();
  } catch (e) {
    return ERROR_LOGIN_TENANTS;
  }
};

export default getLogin;
