import {
  Card,
  Title,
  Input,
  TextArea,
  Label,
  FileUploader,
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxDomRef,
  Ui5CustomEvent,
  ButtonDomRef,
  TextAreaDomRef,
  MessageStrip,
  List,
  StandardListItem,
} from "@ui5/webcomponents-react";
import { ComboBoxSelectionChangeEventDetail } from "@ui5/webcomponents/dist/ComboBox";
import React, { useEffect, useState } from "react";
import { getAddresses, getCounties, getProperties } from "../../../api/tickets";
import { useAuth } from "../../../providers/AuthProvider";
import { useTicket } from "../../../providers/TicketProvider";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import PhotoCameraComponent from "./PhotoCameraComponent";
import _ from "lodash";
import { useProperty } from "../../../providers/PropertyProvider";
import HeaderComponent from "../../components/Header/HeaderComponent";
import BusyIndicatorComponent from "../../components/BusyIndicator/BusyIndicatorComponent";
import { handleImagesArray } from "../../../utils/images";
import { MAX_IMAGES_PER_OBSERVATION } from "../../../constants/constants";

export default function SendTicketComponent() {
  const params = {
    device: "web",
  };
  const acceptedFiles = ".png, .jpg, .jpeg";
  const { getOwnerToken, getTenantToken } = useAuth();
  const { getTicket, setTicket } = useTicket();
  const { getPropertyFromProvider } = useProperty();
  const navigate = useNavigate();
  const [counties, setCounties] = useState([]);
  const [pickedCounty, setPickedCounty] = useState({ id: 0, name: "" });
  const [addresses, setAddresses] = useState([]);
  const [pickedAddress, setPickedAddress] = useState({ id: 0, address: "" });
  const [clonedPlaces, setClonedPlaces] = useState<any>([]);
  const [originalPlaces, setOriginalPlaces] = useState<any>([]);
  const [pickedPlace, setPickedPlaces] = useState({ id: 0, name: "" });
  const [loadingContent, setLoadingContent] = useState(false);
  const [property, setProperty] = useState({
    address: "",
    id: 0,
    model: { documents: [], image: null, name: "", sites: [], project: {} },
  });
  const [redirection, setRedirection] = useState(false);
  const [description, setDescription] = useState("");
  const isMobile = useMediaQuery("(max-width: 933px)");
  const [selectedOption, setSelectedOption] = useState("");
  const [observations, setAllObservations] = useState<any>([]);
  const [clickedObservation, setClickedObservation] = useState<any>();
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [isFailedMessage, setFailedMessage] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<String[]>([]);
  const [infoFiles, setInfoFiles] = useState<String[]>([]);
  const [endForm, setEndForm] = useState(false);
  const [blockedComboBox, setBlockedComboBox] = useState(false);
  const [failedDescription, setFailedDescription] = useState(false);
  const [failedPickedPlace, setFailedPickedPlace] = useState(false);

  useEffect(() => {
    const getAllCounties = async () => {
      const response = await getCounties();
      if (response.status === "failed") {
        return navigate("/error/500");
      }
      if (response.status === "unauthorized") {
        return navigate("/error/403");
      }
      setCounties(response);
      setLoadingContent(false);
    };

    if (!getOwnerToken() && !getTenantToken()) {
      return navigate("/error/403");
    }
    setLoadingContent(true);
    if (!!getTenantToken() && !getOwnerToken()) {
      getAllCounties();
    } else {
      const propertyFromProvider = getPropertyFromProvider();
      setProperty(propertyFromProvider);
      setClonedPlaces(propertyFromProvider.model.sites);
      setOriginalPlaces(propertyFromProvider.model.sites);
      setLoadingContent(false);
    }
  }, []);

  useEffect(() => {
    const getAllAddresses = async () => {
      const response = await getAddresses(pickedCounty.id);
      if (response.status === "failed") {
        return navigate("/error/500");
      }
      if (response.status === "unauthorized") {
        return navigate("/error/403");
      }
      setAddresses(response);
      setLoadingContent(false);
    };
    if (pickedCounty.id) {
      setLoadingContent(true);
      getAllAddresses();
    }
  }, [pickedCounty]);

  useEffect(() => {
    if (redirection) navigate("/schedule-visit/new-ticket");
  }, [redirection]);

  useEffect(() => {
    const getAllProperties = async () => {
      const response = await getProperties(
        getOwnerToken() || getTenantToken() || "",
        pickedAddress.id
      );
      if (response.status === "failed") {
        return navigate("/error/500");
      }
      if (response.status === "unauthorized") {
        return navigate("/error/403");
      }
      setProperty(response);
      if (response.model?.sites) {
        setClonedPlaces(response.model.sites);
        setOriginalPlaces(response.model.sites);
      }
      setLoadingContent(false);
    };
    if (!getOwnerToken() && getTenantToken()) {
      setLoadingContent(true);
      getAllProperties();
    }
  }, [pickedAddress]);

  const handleComboBoxSelection = async (
    e: Ui5CustomEvent<ComboBoxDomRef, ComboBoxSelectionChangeEventDetail>,
    comboBoxType: string
  ) => {
    e.preventDefault();
    if (comboBoxType === "counties") {
      const getCounty = counties.find(
        (county: { id: number; name: string }) => {
          return county.name === e.target.value;
        }
      );
      if (getCounty) {
        setPickedCounty(getCounty);
      }
    }
    if (comboBoxType === "addresses") {
      const foundAddress = addresses.find(
        (address: { id: number; address: string }) => {
          return address.address === e.target.value;
        }
      ) || { id: 0, address: "" };
      return setPickedAddress(foundAddress);
    }
    if (comboBoxType === "places") {
      const foundPlace = clonedPlaces.find(
        (prop: any) => prop.name === e.target.value
      );
      if (foundPlace) {
        setPickedPlaces(foundPlace);
      } else {
        setPickedPlaces({ name: "", id: 0 });
      }
      setFailedPickedPlace(false);
    }
  };

  const handleFiles = (e: any) => {
    const files = isMobile ? e : e.detail.files;

    let namesForSanitization;

    if (isMobile) {
      namesForSanitization = files.map((file: File) => `"${file.name}"`).join(' " ');
    }
    else {
      namesForSanitization = e.target.value;
    }

    const imagesUtilResponse = handleImagesArray(files, namesForSanitization, selectedFiles.length);

    setSelectedFiles((prevState: any) => [...prevState, ...imagesUtilResponse.fileNames]);
    setInfoFiles((prevState: any) => [...prevState, ...imagesUtilResponse.files]);
  };

  const handleTicketCreation = (
    e: React.MouseEvent<ButtonDomRef, MouseEvent>
  ) => {
    e.preventDefault();
    if (pickedPlace.id && pickedPlace.name && description) {
      setAllObservations((prevState: any) => [
        ...prevState,
        {
          description,
          imageQuantity: infoFiles.length,
          site: pickedPlace.id,
          images: infoFiles,
          sectorName: pickedPlace.name,
        },
      ]);
    }
    setEndForm(true);
  };

  const handleDescription = (e: Ui5CustomEvent<TextAreaDomRef, never>) =>
  {
    setDescription(e.target.value || "");
    if (description) {
      setFailedDescription(false);
    }
  }

  const cleanForm = () => {
    setDescription("");
    setPickedPlaces({ name: "", id: 0 });
    setSelectedFiles([]);
  };

  useEffect(() => {
    if (clonedPlaces.length === 0 && pickedPlace.name) {
      setFailedMessage(true);
    }
  }, [clonedPlaces]);

  useEffect(() => {
    cleanForm();
    setClonedPlaces((prevState: any) =>
      prevState.filter((element: any) => element.id !== pickedPlace.id)
    );
    if (endForm) {
      const obj = { location: property.id, remarks: observations };
      setTicket(obj);
      setRedirection(true);
    }
  }, [observations, endForm]);

  const handleAddingObservation = () => {
    setAllObservations((prevState: any) => [
      ...prevState,
      {
        description,
        imageQuantity: infoFiles.length,
        site: pickedPlace.id,
        images: infoFiles,
        sectorName: pickedPlace.name,
      },
    ]);
  };

  useEffect(() => {
    setIsDisabledButton(
      (!getTenantToken() ||
        pickedCounty.name === "" ||
        pickedAddress.address === "" ||
        pickedPlace.name === "" ||
        description === "") &&
        (!getOwnerToken() || pickedPlace.name === "" || description === "")
    );
  }, [
    getTenantToken,
    getOwnerToken,
    pickedAddress,
    pickedCounty,
    pickedPlace,
    description,
    infoFiles,
    observations,
  ]);

  const handleClickedObs = (obs: any) => {
    setClickedObservation(obs);
    const foundPlace = originalPlaces.find(
      (prop: any) => prop.name === obs.sectorName
    );
    setBlockedComboBox(true);
    setPickedPlaces(foundPlace);
    setDescription(obs.description);
    const fileListArray: File[] = Array.from(obs.images);
    const fileNames = fileListArray.map((file: File) => file.name);
    setInfoFiles((prevState: String[]) => [...prevState, obs.images]);
    setSelectedFiles((prevState: any) => [...prevState, fileNames]);
  };

  const cancelEdition = () => {
    cleanForm();
    setBlockedComboBox(false);
    setClickedObservation(undefined);
    setInfoFiles([]);
  };

  const eliminateObservation = () => {
    const newData = observations.filter(
      (obs: any) => obs.sectorName !== clickedObservation.sectorName
    );
    setAllObservations(newData);
    setClonedPlaces((prevState: any) => [...prevState, pickedPlace]);
    cleanForm();
    setClickedObservation(undefined);
    setBlockedComboBox(false);
    setInfoFiles([]);
  };

  const editingObservation = (
    e: React.MouseEvent<ButtonDomRef, MouseEvent>
  ) => {
    e.preventDefault();
  
    const filteredObservations = observations.filter(
      (observation: any) => observation.sectorName !== pickedPlace.name
    );
  
    const newObservation = {
      description,
      imageQuantity: infoFiles.length,
      site: pickedPlace.id,
      images: infoFiles,
      sectorName: pickedPlace.name,
    };

    if (description && pickedPlace.id !== 0) {
      setAllObservations([...filteredObservations, newObservation]);
      setClickedObservation(undefined);
      setIsDisabledButton(false);
      setInfoFiles([]);
      setBlockedComboBox(false);
    }
    
    if (!description) setFailedDescription(true);

    if (pickedPlace.id === 0) setFailedPickedPlace(true);
  };

  const handleDeleteUploadedImages = () => {
    setInfoFiles([]);
    setSelectedFiles([]);
  }

  return (
    <>
      {params.device === "web" && selectedOption !== "camera" && (
        <>
          <div className="m-20">
            <Card
              className="ml-1 mt-6 cards-background"
              style={{ opacity: loadingContent ? 0.5 : 1 }}
            >
              <HeaderComponent></HeaderComponent>
              <BusyIndicatorComponent loading={loadingContent} />
              <div className="draaft-front__landing-card__header m-3">
                <Title level="H2">SOLICITUD DE POSTVENTA</Title>
              </div>
              {observations.length > 0 && (
                <div className="mt-0 bottom-div mr-10 mt-15">
                  <List>
                    {observations.map((obs: any, key: number) => {
                      return (
                        <StandardListItem
                          key={`${key} - ${obs.description}`}
                          description={`${key + 1}`}
                          onClick={() => handleClickedObs(obs)}
                        >
                          {obs.sectorName} - {obs.description}
                        </StandardListItem>
                      );
                    })}
                  </List>
                </div>
              )}
              <div className="m-3">Ingrese el detalle de su solicitud:</div>
              {!getOwnerToken() && (
                <div className="mt-2 ml-2 mr-3">
                  <ComboBox
                    onChange={(e) => handleComboBoxSelection(e, "counties")}
                    valueState="None"
                    placeholder="Elija comuna"
                    className="draaft-front__login-inputs m-1 w-full"
                    required
                  >
                    {counties.map((county: any) => (
                      <ComboBoxItem
                        key={`${county.id} - ${county.address}`}
                        text={county.name}
                      />
                    ))}
                  </ComboBox>
                </div>
              )}
              {!getOwnerToken() && (
                <div className="mt-2 ml-2 mr-3">
                  <ComboBox
                    onChange={(e) => handleComboBoxSelection(e, "addresses")}
                    valueState="None"
                    disabled={!pickedCounty.name}
                    placeholder="Elija dirección"
                    className="draaft-front__login-inputs m-1 w-full"
                    required
                  >
                    {addresses.map((address: any) => (
                      <ComboBoxItem
                        key={`${address.id} - ${address.address}`}
                        text={address.address}
                      />
                    ))}
                  </ComboBox>
                </div>
              )}
              <div className="mt-2 ml-2 mr-3">
                {isFailedMessage && (
                  <MessageStrip design="Negative" onClose={function _a() {}}>
                    No se pueden generar más tickets
                  </MessageStrip>
                )}
                <ComboBox
                  onChange={(e) => handleComboBoxSelection(e, "places")}
                  valueState="None"
                  disabled={
                    (!pickedAddress.address && !!getTenantToken()) ||
                    (clonedPlaces.length === 0 && !!getOwnerToken()) ||
                    blockedComboBox
                  }
                  placeholder="Elija sector de la propiedad"
                  className="draaft-front__login-inputs m-1 w-full"
                  value={pickedPlace.name}
                  required
                >
                  {clonedPlaces.map((prop: any) => (
                    <ComboBoxItem
                      key={`${prop.id} - ${prop.name}`}
                      text={prop.name}
                    />
                  ))}
                </ComboBox>
                {failedPickedPlace && (
                  <p className="ml-1 text-red-600">
                    Es obligatorio seleccionar un lugar.
                  </p>
                )}
                <TextArea
                  placeholder="Descripción"
                  value={description}
                  onChange={(e) => handleDescription(e)}
                  disabled={clonedPlaces.length === 0}
                  maxlength={200}
                  className="m-1"
                />
                {failedDescription && (
                  <p className="ml-1 text-red-600">
                    Es obligatorio agregar una descripción.
                  </p>
                )}
              </div>
              <div className="m-3">
                {selectedFiles.length < MAX_IMAGES_PER_OBSERVATION && (
                <FileUploader
                  multiple
                  hideInput
                  onChange={handleFiles}
                  accept={acceptedFiles}
                  disabled={selectedFiles.length >= MAX_IMAGES_PER_OBSERVATION}
                >
                  <Button
                    icon="upload"
                    accessible-name-ref="upload-single-file-label"
                  >
                    Agregar Imágenes
                  </Button>
                </FileUploader>
                )}
                {selectedFiles.length < MAX_IMAGES_PER_OBSERVATION && isMobile && (
                  <PhotoCameraComponent
                    onChange={handleFiles}
                    hideInput
                    multiple
                  ></PhotoCameraComponent>
                )}
                {selectedFiles.length > 0 &&
                  (
                    <>
                    {selectedFiles.map((file) => <p className="ml-3">{file}</p>)}
                    <Button
                    icon="upload"
                    accessible-name-ref="upload-single-file-label"
                    onClick={handleDeleteUploadedImages}
                    className="bg-red-500 hover:bg-red-800 text-white ml-3 mt-2"
                  >
                  Borrar todas las imágenes
                  </Button>
                  </>
                    )}
              </div>
              <div className="m-3">
              <MessageStrip
                design="Information"
                hideCloseButton
              >
                Sólo se pueden agregar un máximo de 3 imágenes por cada observación, de tipo .jpg, .png o .jpeg. Máximo de 3 MB por el total de imágenes.
              </MessageStrip>
              </div>
              {clickedObservation && (
                <div className="draaft-front__landing-card__buttons flex mt-10 ml-3 mr-3 mb-3">
                  <Button
                    design="Default"
                    className="w-full"
                    onClick={() => cancelEdition()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    design="Default"
                    className="bg-red-500 hover:bg-red-800 text-white mb-2 w-full ml-2"
                    disabled={isDisabledButton || clonedPlaces.length === 0}
                    onClick={eliminateObservation}
                  >
                    Eliminar
                  </Button>
                  <Button
                    design="Default"
                    onClick={(e) => editingObservation(e)}
                    disabled={isDisabledButton}
                    className="affirmative-button hover:bg-blue-700 text-white mb-2 w-full ml-2"
                  >
                    Editar observación
                  </Button>
                </div>
              )}
              {!clickedObservation && (
                <div className="draaft-front__landing-card__buttons flex mt-10 ml-3 mr-3 mb-3">
                  <Button
                    design="Default"
                    className="w-full"
                    onClick={() =>
                      navigate(getOwnerToken() ? "/personal-data" : "/login")
                    }
                  >
                    Cancelar
                  </Button>
                  <Button
                    design="Default"
                    className="bg-gray-500 hover:bg-gray-800 text-white mb-2 w-full ml-2"
                    disabled={isDisabledButton || clonedPlaces.length === 0}
                    onClick={handleAddingObservation}
                  >
                    Agregar observación
                  </Button>
                  <Button
                    design="Default"
                    onClick={(e) => handleTicketCreation(e)}
                    disabled={isDisabledButton && observations.length < 1}
                    className="affirmative-button hover:bg-blue-700 text-white mb-2 w-full ml-2"
                  >
                    Agendar hora
                  </Button>
                </div>
              )}
            </Card>
          </div>
        </>
      )}
    </>
  );
}
