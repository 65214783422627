import {
  Card,
  Panel,
  Title,
  Text,
  Input,
  Ui5CustomEvent,
  InputDomRef,
  Button,
  MessageStrip,
} from "@ui5/webcomponents-react";
import React, { FormEvent, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { recoverPassword } from "../../../api/login";
import { useRut } from "../../../utils/react-rut-formatter";
import BusyIndicatorComponent from "../../components/BusyIndicator/BusyIndicatorComponent";
import HeaderComponent from "../../components/Header/HeaderComponent";
import SuccesfulMessagePasswordComponent from "./SuccesfulMessagePasswordComponent";

export default function RecoverPassComponent() {
  const { rut, updateRut } = useRut();
  const [loadingContent, setLoadingContent] = useState(false);
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [captchaValue, setReCaptchaValue] = useState(null);
  const navigate = useNavigate();
  const siteKeyForDev = '6Ld6QZUpAAAAAN-38rGx79eMsqXKrSJ0I9gnRGBp';

  const handleUpdateRut = (e: Ui5CustomEvent<InputDomRef, never>) =>
    updateRut(e.target.value || "");

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    setFailedMessage('');
    if (captchaValue) {
      setLoadingContent(true);
      const response = await recoverPassword({ dni: rut.raw }, captchaValue);
      setLoadingContent(false);
      if (
        response.status === 'failed'
      ) {
        return setFailedMessage(response.reason);
      }
      if ( response.status === 'unauthorized' ) {
        return navigate('/error/403');
      }
      setIsSuccesful(true);
    }
    else {
      setFailedMessage('Captcha inválido. Por favor, reintentar');
    }
  };

  const succesfulMessageProps = {
    title: 'Solicitud de cambio de contraseña enviado.',
    textContent: 'Se ha enviado una solicitud con para recuperar su contraseña. Revise su email registrado en la aplicación para continuar con el flujo.',
    buttonText: 'Volver al inicio',
  }

  const handleCaptchaValue = (value: any) => {
    setFailedMessage('');
    setReCaptchaValue(value);
  }

  return (
    <>
      <div className="m-20">
          {isSuccesful && (<SuccesfulMessagePasswordComponent props={succesfulMessageProps} />)}
        <Card className="cards-background" style={{ opacity: loadingContent ? 0.5 : 1 }}>
          <BusyIndicatorComponent loading={loadingContent} />
          <HeaderComponent></HeaderComponent>
          <div className="mt-5 ml-2 mr-3">
            <Title level="H3" className="m-1">Recuperación de contraseña</Title>
            <Text className="m-1">Ingrese su rut para recuperar su contraseña:</Text>
          </div>
          <div className="mt-5 ml-2 mr-3">
            <Input
              id="input-6"
              className="draaft-front__login-inputs m-1 w-full"
              value={rut.formatted}
              onChange={(e) => handleUpdateRut(e)}
              placeholder="RUT"
              required
            />
          </div>
          {failedMessage && (
            <div className="m-2">
              <MessageStrip design="Negative" onClose={() => setFailedMessage('')}>
                {failedMessage}
              </MessageStrip>
            </div>
          )}
          <div className="draaft-front__landing-card__buttons flex flex-col h-full justify-center items-center ml-2 mr-2 mt-4 mb-6">
            <Button
              onClick={(e) => handleSubmit(e)}
              design="Default"
              disabled={rut.raw === "" && !captchaValue}
              className="bg-green-700 text-white px-4 py-2 mb-2 w-full"
            >
              Recuperar contraseña
            </Button>
          </div>
          <div className="m-5 flex flex-col h-full justify-center items-center">
            <ReCAPTCHA
              sitekey={siteKeyForDev}
              onChange={handleCaptchaValue}
            />
          </div>
        </Card>
      </div>
    </>
  );
}
