import { Panel, Button, Input, InputDomRef, Ui5CustomEvent, MessageStrip } from "@ui5/webcomponents-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserData } from "../../../api/user";
import { validateEmail } from "../../../utils/email";
import { validatePhone } from "../../../utils/phone";
import { formatRut, useRut } from "../../../utils/react-rut-formatter";
import BusyIndicatorComponent from "../../components/BusyIndicator/BusyIndicatorComponent";

export default function PersonalDataEditComponent (props: any) {
    const {personalData, userToken} = props;
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const { rut, updateRut } = useRut();
    const [isEditionMode, setEditionMode] = useState(false);
    const [loadingContent, setLoadingContent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const handleEditionMode = (editionMode: boolean) =>
      setEditionMode(editionMode);

    const handleEditedData = async () => {
        setLoadingContent(true);
        setErrorMessage('');
        const response = await updateUserData(userToken, {phone, mail});
        if (
          response.status === 'failed'
        ) {
          return setErrorMessage(response.reason);
        }
        if ( response.status === 'unauthorized' ) {
          return navigate('/error/403');
        }
        if (response) {
            setEditionMode(false);
            setPhone(response.phone);
            setMail(response.mail);
        }
        setLoadingContent(false);
    };
  
    const handleNumberChange = (e: Ui5CustomEvent<InputDomRef, never>) => {
      setPhone(e.target.value || "");
      setIsValidPhone(validatePhone(e.target.value || ""));
    };
  
    const handleEmailChange = (e: Ui5CustomEvent<InputDomRef, never>) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsValidEmail(emailRegex.test(e.target.value));
      setMail(e.target.value || "");
    }

    useEffect(() => {
        setName(personalData.name);
        setPhone(personalData.phone);
        setMail(personalData.mail);
        updateRut(personalData.dni);
    }, [personalData]);

    return (
        <Panel
          accessibleRole="Form"
          headerLevel="H2"
          headerText="MIS DATOS"
          onToggle={function _a() {}}
          collapsed={true}
          className="panel-headers"
          style={{ opacity: loadingContent ? 0.5 : 1 }}
        >
          <BusyIndicatorComponent loading={loadingContent} />
          {errorMessage && (
            <div className="m-2">
              <MessageStrip design="Negative" onClose={() => setErrorMessage('')}>
                {errorMessage}
              </MessageStrip>
            </div>
          )}
          {!isEditionMode && (
            <>
              <div className="flex mb-2">
                <div className="w-1/2">Nombre:</div>
                <div className="w-1/2 font-bold">{personalData.name}</div>
              </div>
              <div className="flex mb-2">
                <div className="w-1/2">Rut:</div>
                <div className="w-1/2 font-bold">
                  {formatRut(personalData.dni)}
                </div>
              </div>
              <div className="flex mb-2">
                <div className="w-1/2">Email:</div>
                <div className="w-1/2 font-bold">{personalData.mail}</div>
              </div>
              <div className="flex mb-2">
                <div className="w-1/2">Teléfono:</div>
                <div className="w-1/2 font-bold">{personalData.phone}</div>
              </div>
              <div className="flex justify-end">
                <Button
                  design="Default"
                  className="affirmative-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => handleEditionMode(true)}
                >
                  Editar Datos
                </Button>
              </div>
            </>
          )}
          {isEditionMode && (
            <>
              <div className="flex mb-2">
                <div className="w-1/2">Nombre:</div>
                <div className="w-1/2 font-bold">{personalData.name}</div>
              </div>
              <div className="flex mb-2">
                <div className="w-1/2">Rut:</div>
                <div className="w-1/2 font-bold">
                  {formatRut(personalData.dni)}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">Email:</div>
                <Input
                  id="input-6"
                  className="w-1/2"
                  value={mail}
                  placeholder="Correo electrónico"
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="flex mb-2">
                <div className="w-1/2">&nbsp;</div>
                {!isValidEmail && (
                  <p className="ml-1 text-red-600">
                  El mail ingresado no cumple con el formato xxxx@xxxx.xxx
                  </p>
                )}
              </div>
              <div className="flex mb-2">
                <div className="w-1/2">Teléfono:</div>
                <Input
                  id="input-6"
                  className="w-1/2"
                  value={phone}
                  placeholder="Teléfono"
                  onChange={(e) => handleNumberChange(e)}
                  maxlength={9}
                  required
                />
              </div>
              <div className="flex justify-end">
                <Button
                  className="bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  onClick={() => handleEditionMode(false)}
                >
                  Cancelar
                </Button>
                <Button
                  className="affirmative-button text-white font-bold py-2 px-4 rounded"
                  onClick={handleEditedData}
                >
                  Guardar
                </Button>
              </div>
            </>
          )}
        </Panel>);
}