import { Button, Icon } from "@ui5/webcomponents-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const goBack = useNavigate();
  return (
    <>
      <Button
        className="transparent-buttons"
        onClick={() => goBack(-1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M19 12H6M12 5l-7 7 7 7" />
        </svg>{" "}
        VOLVER
      </Button>
    </>
  );
}
