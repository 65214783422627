import React, { createContext, useContext, useEffect, useState } from 'react';

interface AuthContextType {
  ownerToken: string | null;
  tenantToken: string | null;
  getOwnerToken: () => string | null;
  getTenantToken: () => string | null;
  setOwnerToken: (token: string | null) => void;
  setTenantToken: (token: string | null) => void;
  clearAllToken: () => void; // Nueva función para borrar el token
}

const AuthContext = createContext<AuthContextType>({
  ownerToken: null,
  tenantToken: null,
  getOwnerToken: () => null,
  getTenantToken: () => null,
  setOwnerToken: () => {},
  setTenantToken: () => {},
  clearAllToken: () => {},
});

export const AuthProvider: React.FC = ({ children }: any) => {
  const [ownerToken, setOwnerToken] = useState<string | null>(() => {
    return localStorage.getItem('gprOwnerToken');
  });

  const [tenantToken, setTenantToken] = useState<string | null>(() => {
    return localStorage.getItem('gprTenantToken');
  });

  useEffect(() => {
    if (tenantToken) {
      localStorage.setItem('gprTenantToken', tenantToken);
    } else {
      localStorage.removeItem('gprTenantToken');
    }
  }, [tenantToken]);

  useEffect(() => {
    if (ownerToken) {
      localStorage.setItem('gprOwnerToken', ownerToken);
    } else {
      localStorage.removeItem('gprOwnerToken');
    }
  }, [ownerToken]);

  const getOwnerToken = () => {
    return ownerToken;
  };

  const getTenantToken = () => {
    return tenantToken;
  };

  const clearAllToken = () => {
    localStorage.removeItem('gprOwnerToken');
    localStorage.removeItem('gprTenantToken');
    setOwnerToken(null);
    setTenantToken(null);
  }

  return (
    <AuthContext.Provider value={{ ownerToken, tenantToken, getOwnerToken, getTenantToken, setOwnerToken, setTenantToken, clearAllToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => useContext(AuthContext);