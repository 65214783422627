import React from "react";
import { useParams } from "react-router-dom";
import ChangePasswordComponent from "./components/ChangePasswordComponent";
import RecoverPassComponent from "./components/RecoverPassComponent";

export default function RecoverPass() {
  const { token } = useParams();
  
  return (
    <>
      {token !== 'new-mail' && <ChangePasswordComponent token={token} />}
      {token === 'new-mail' && <RecoverPassComponent />}
    </>
  );
}
