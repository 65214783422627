import { Button, Card, Title } from "@ui5/webcomponents-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFoundComponent () {
    const goBack = useNavigate();
    const navigate = useNavigate();
    return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Card className="m-20">
            <div className="draaft-front__landing-card__header m-3">
              <img
                slot="logo"
                src="/gpr-logo.jpeg"
                className="draaft-front-login-image"
                alt="image"
              />
            </div>
            <div className="draaft-front__landing-card__header m-3">
              <Title level="H2" className="mb-10">
                El recurso que intenta buscar no existe.
              </Title>
            </div>
            <div className="m-2">
              <Button
                design="Default"
                onClick={() => navigate("/login")}
                className="affirmative-button text-white mb-2 w-full"
              >
                Volver a inicio
              </Button>
              <Button
                design="Default"
                onClick={() => goBack(-1)}
                className="bg-gray-500 text-white mb-2 w-full"
              >
                Volver atrás
              </Button>
            </div>
          </Card>
        </div>
    );
}