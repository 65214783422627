import React from "react";
import BackButton from "../BackButton/BackButton";

export default function HeaderComponent() {
  return (
    <>
      <div className="flex justify-between items-center m-10">
        <BackButton></BackButton>
        <img src="/gpr-logo.jpeg" className="w-40 h-auto mr-10" alt="image" />
      </div>
    </>
  );
}
