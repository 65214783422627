import { Button } from '@ui5/webcomponents-react';
import React, { useRef } from 'react';

const PhotoCameraComponent = ({ onChange, multiple, disabled = false, hideInput = true }: any) => {
    const inputFileRef = useRef<HTMLInputElement>(document.createElement('input'));

    const handleClick = () => {
        inputFileRef.current.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            let totalSize = 0;
            let validFiles: File[] = [];
            for (let i = 0; i < files.length; i++) {
                totalSize += files[i].size;
                if (totalSize <= 2 * 1024 * 1024 && validFiles.length < 3) {
                    validFiles.push(files[i]);
                } else {
                    // Si el archivo excede el límite, eliminamos el último archivo agregado
                    totalSize -= files[i].size;
                }
            }
            if (validFiles.length < files.length) {
                // Si algunos archivos fueron eliminados, mostramos un mensaje de advertencia
                console.log("Se eliminaron archivos debido a que exceden el límite de tamaño o cantidad");
            }
            // Llamamos a la función onChange con los archivos válidos
            onChange(validFiles);
        }
    };

    return (
        <>
            <div className="ui5-file-uploader-root">
                <input
                    type="file"
                    ref={inputFileRef}
                    accept='image/*'
                    capture={'environment'}
                    onChange={handleFileChange}
                    multiple={multiple}
                    disabled={disabled}
                    hidden={hideInput}
                    aria-hidden="true"
                    tabIndex={-1}
                />
                <Button onClick={handleClick} icon='camera' design='Default'>
                    Tomar Foto
                </Button>
            </div>
        </>

    );
}

export default PhotoCameraComponent;
