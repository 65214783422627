import { Button, Title } from "@ui5/webcomponents-react";
import { format, add } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ScheduleMessageComponent(props: any) {
  const { ticketNumber, dayPicked, hourPicked, isOwner, isRescheduling } =
    props;
  const navigate = useNavigate();
  const handleRedirection = () => {
    if (isOwner) navigate("/personal-data");
    else navigate("/login");
  };
  const ticketCreatedMessage = "Su visita está agendada para el";
  const ticketRescheduledMessage = "Su visita ha sido reagendada para el";
  return (
    <div className="success-message">
      <div className="draaft-front__landing-card__header m-5">
        <img
          slot="logo"
          src="/gpr-logo.jpeg"
          className="draaft-front-login-image"
          alt=""
        />
      </div>
      <Title level="H3">Listo, hemos recibido su solicitud.</Title>
      <Title level="H3">Nº {ticketNumber}</Title>
      <p style={{ textAlign: "center" }}>
        {isRescheduling ? ticketRescheduledMessage : ticketCreatedMessage}{" "}
        {dayPicked}, entre las {hourPicked}.
      </p>
      <Button
        className="affirmative-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5"
        onClick={() => handleRedirection()}
      >
        Volver al inicio
      </Button>
    </div>
  );
}
